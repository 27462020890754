import BannerImageDesktop from '../../../../assets/img/Venues Banner.jpg';
import BannerImageMobile from '../../../../assets/img/Venues Banner Mobile.jpg';
import Banner from '../components/Banner';
import VenueCard from '../components/VenueCard';
import { cardData, individualData } from '../data';
import VenueMobileCard from '../components/VenueMobileCard';

const Venues = () => {
  const tournamentId = location.pathname.split('/')[2];
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  console.log('ismb', isMobile);

  return (
    <>
      <Banner
        // title={bannerData.name}
        img={isMobile ? BannerImageMobile : BannerImageDesktop}
      />
      <div className="px-6 mt-11 md:mt-24 pt-1 text-center mb-8">
        <h1 className="font-extrabold text-blue-kheloBlue font-montserrat md:text-4xl mb-2 text-lg">
          Venues
        </h1>
      </div>
      <div className="p-14 md:flex flex-wrap gap-2 justify-center hidden">
        {cardData.map((el, i) => (
          <VenueCard
            key={i}
            {...el}
            {...individualData[el.slug]}
            image={individualData[el.slug]?.card_image}
            redirectTo={`/micro-site/${tournamentId}/landing/venues/${el?.slug}`}
          />
        ))}
      </div>
      <div className="grid grid-cols-2 gap-2 md:hidden px-6 py-8">
        {cardData.map((el, i) => (
          <VenueMobileCard
            key={i}
            {...el}
            {...individualData[el.slug]}
            image={individualData[el.slug]?.card_image}
            redirectTo={`/micro-site/${tournamentId}/landing/venues/${el?.slug}`}
          />
        ))}
      </div>
    </>
  );
};

export default Venues;
