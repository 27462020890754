import React, { useState, useEffect } from 'react';
import AttractionCard from 'micro-site/Components/AttractionsCard';
import { useEventAttractions } from 'micro-site/queries/hooks';
import Helmet from 'react-helmet';

export default function Attractions() {
  const eventAttractionsListQueryData = useEventAttractions({
    tournament_id: '221',
  });
  const [eventAttractionsByType, setEventAttractionsByType] = useState({});

  useEffect(() => {
    if (eventAttractionsListQueryData.isFetched && eventAttractionsListQueryData.data)
      setEventAttractionsByType(
        eventAttractionsListQueryData?.data?.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.event_attraction_type]: prev?.[curr?.event_attraction_type]
              ? [...prev?.[curr?.event_attraction_type], { ...curr }]
              : [{ ...curr }],
          };
        }, {})
      );
  }, [eventAttractionsListQueryData.data]);

  return (
    <div className="px-4 md:px-6 pt-4 pb-14 max-w-fhd mx-auto no-scrollbar">
      <Helmet>
        <title>Special Events & Attractions | KIYG Haryana 2021</title>
        <meta
          name="description"
          content="Don't miss out on the SPECIAL MOMENTS at #KIYG2021. Keep checking this page for LATEST UPDATES on all SPECIAL EVENTS, GUEST APPEARANCES and lots more!"
        />
        <meta property="og:title" content="Special Events & Attractions | KIYG Haryana 2021" />
        <meta
          property="og:description"
          content="Don't miss out on the SPECIAL MOMENTS at #KIYG2021. Keep checking this page for LATEST UPDATES on all SPECIAL EVENTS, GUEST APPEARANCES and lots more!"
        />
      </Helmet>
      <hr className="w-16 mx-0 md:hidden my-0 md:w-60 bg-gray-750 h-0.5 md:h-1 mt-1 md:mt-6" />
      {Object.keys(eventAttractionsByType).map((group) => {
        const data = eventAttractionsByType[group];
        return (
          <div key={group}>
            <h3 className="text-lg md:pb-3 md:shadow-shadowBottom md:text-2xl font-bold text-gray-750 mt-10">
              {group}
            </h3>
            <div className="grid auto-rows-min mt-10 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 lg:gap-10">
              {data.map((item, index) => {
                return <AttractionCard key={index} data={item} />;
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}
