import React, { useRef, useState } from 'react';

import ListLoader from 'components/ListLoader';
import WinnerCard from './WinnerCard';
import FilterIcon from '../../assets/img/Filters.svg';
import SelectList from 'components/select-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useSportEvent } from 'micro-site/queries/hooks';
import { useHistory } from 'react-router-dom';
import { useShowKhelo, useInfiniteScroll } from 'utils/HooksUtils';
import DrawEventForm from './DrawEventForm';

export default function WinnerCardList({
  data,
  selectedValue,
  hideFilter,
  setSelectedValue,
  placeholder,
  listData,
  sport,
}) {
  const isKhelo = useShowKhelo();
  const [filterPopup, setFilterPopup] = useState(false);
  const filterPopupHandler = () => {
    setFilterPopup(!filterPopup);
  };
  const [params, setParams] = useState('');
  const [viewDrawsScreenMobile, setViewDrawsScreenMobile] = useState(false);
  const handleDrawFormat = (schema) => {
    setParams(schema);
  };
  const infiniteRef = useRef(null);

  useInfiniteScroll(infiniteRef, data.fetchNextPage, data.isFetching, true);
  const history = useHistory();
  const sportEventQueryData = useSportEvent({
    tournament: history.location.pathname.split('/')[2],
    sport: params?.sport,
    gender: params?.gender,
    age_group: params?.age_group,
    sport_category: params?.sport_category,
    sub_category: params?.sub_category,
  });
  const clearFilterHandler = () => {
    setSelectedValue('All Sports');
    setFilterPopup(false);
  };
  if (sportEventQueryData.isFetched === true && params) {
    try {
      history.push(
        `/public/score/${sportEventQueryData.data[0].digital_scoring_format?.toLowerCase()}/${
          sportEventQueryData.data[0].sport_event
        }`
      );
    } catch (error) {
      console.debug(error.message);
    }
  }
  return !viewDrawsScreenMobile ? (
    <>
      {' '}
      <div className="mb-3 mt-4 md:hidden flex justify-between">
        <div className="flex gap-1">
          <div onClick={filterPopupHandler} className="flex cursor-pointer items-end">
            <img src={FilterIcon} className="cursor-pointer w-5 h-5" alt="filter icon" />
            <p className="text-gray-450 text-sm">Filters</p>
          </div>
        </div>
        <div
          className="text-sm font-medium text-blue-350 cursor-pointer"
          onClick={() => setViewDrawsScreenMobile(true)}
        >
          View Results by Sport
        </div>
      </div>
      {Array.isArray(data?.data?.pages) && data?.data?.pages?.[0]?.data?.length > 0 ? (
        <div
          className={`mt-4 py-2 rounded-lg overflow-y-auto ${
            isKhelo ? 'bg-blue-kheloBlue400' : 'bg-blue-dark'
          } px-4 md:hidden`}
          ref={infiniteRef}
          style={{ height: '600px' }}
        >
          <ListLoader
            queryData={data}
            queryKey="winners"
            isInfinite
            isInfiniteFetching={data.isFetching}
          >
            {({ item, index }) => {
              return <WinnerCard key={index} data={item} />;
            }}
          </ListLoader>
        </div>
      ) : (
        <p className="w-full text-center my-20 text-sm text-gray-750">
          {isKhelo
            ? 'Keep A Close Watch On This Space To Find Out The Best State Level Performance in KIYG'
            : 'Keep a close watch on this space to find out the best school for sport in Uttarakhand.'}
        </p>
      )}
      {!hideFilter ? (
        <div
          className={`fixed overflow-y-auto shadow-footerPopUp h-3/4 rounded-t-xl w-full bg-white flex flex-col justify-between px-4 py-6 bottom-0 right-0 left-0 z-20 ${
            filterPopup ? 'block' : 'hidden'
          } `}
        >
          <div>
            <div className="flex justify-between">
              <h3 className="text-base font-bold gray-text-750">Filters </h3>
            </div>
            <div className="mt-8">
              <div className="mb-4 w-full">
                <p className="text-sm mb-1 ">Sport</p>
                <SelectList
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  placeholder={placeholder}
                  listData={listData}
                  sport={sport}
                  fullWidth={true}
                  filterClose={true}
                  setFilterPopup={() => {}}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 items-center text-sm md:text-base mt-auto sticky w-full bottom-0 py-2 bg-white">
            <button
              onClick={() => setFilterPopup(false)}
              className="btn btn-primary btn-md btn-block  bg-gray-750 hover:bg-blue-800 flex gap-1 items-center"
            >
              <FontAwesomeIcon icon={faCheck} />
              <p>Apply</p>
            </button>
            <button
              onClick={clearFilterHandler}
              className="flex gap-1 items-center justify-center cursor-pointer reg-btn-outline-dark mr-1 w-full hover:bg-gray-250 font-semibold rounded-lg mt-2 py-2 text-gray-dark"
            >
              <FontAwesomeIcon icon={faTimes} />
              <p>Clear</p>
            </button>
          </div>
        </div>
      ) : null}
    </>
  ) : (
    <div>
      <h1 className="text-base text-black font-bold my-3">View Results by Sport</h1>
      <DrawEventForm
        handleDrawFormat={handleDrawFormat}
        isLoading={sportEventQueryData?.isLoading}
        customSubmitText={'View Results'}
      />
    </div>
  );
}
