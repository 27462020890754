import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const VenueCard = ({ width, name, subtitle, address, image, slug, redirectTo }) => {
  const [hovered, setHovered] = useState(false);
  const history = useHistory();

  return (
    <div
      className="relative min-h-fit rounded-lg font-montserrat"
      style={{
        backgroundImage: `${
          !hovered
            ? `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url('${image}')`
            : `linear-gradient(0deg, rgba(48, 18, 0, 0.7), rgba(48, 18, 0, 0.7)),url('${image}')`
        }`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width,
      }}
      onMouseOver={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
    >
      <div
        className={`flex flex-col gap-8 items-center opacity-0 transition-all ease-in-out delay-200 p-6 ${
          hovered ? 'opacity-100' : ''
        }`}
      >
        <div className="flex flex-col items-center">
          <h2 className="text-white font-extrabold text-3.5xl">{name}</h2>
          <h2 className="text-green-500 font-extrabold text-3.5xl">{subtitle}</h2>
        </div>
        <p className="text-white font-medium text-xs font-montserrat">{address}</p>
        <button
          className="text-white bg-green-500 rounded-lg font-bold font-roboto p-2 cursor-pointer"
          onClick={() => history.push(redirectTo)}
        >
          View Venue
        </button>
      </div>
      <h2
        className={`absolute inset-0 h-full w-full flex flex-col justify-center items-center text-white font-extrabold text-3.5xl text-center ${
          hovered ? 'opacity-0' : ''
        } transition-opacity delay-200 ease-in-out pointer-events-none`}
      >
        {name}
      </h2>
    </div>
  );
};

export default VenueCard;
