import Tabs from 'components/Tab';
import EventList from './EventList';
import MatchCenterScoreCard from './MatchCenterScoreCard';
import footballIcon from '../../../../assets/img/footballIcon.svg';
import redCard from '../../../../assets/img/redCard.svg';
import React, { useMemo } from 'react';
import { Cards } from './Cards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
// import { useAdvanceDSLeague } from 'micro-site/queries/hooks';

const MatchCenterPage = ({ data, closeModal }) => {
  const footballEvents = ['red_cards', 'substitutes', 'yellow_cards', 'goals'];
  const timeline =
    useMemo(() => {
      const toSort = footballEvents?.reduce((previousEvent, currentEvent) => {
        if (!previousEvent && !currentEvent) return [];
        if (
          !data?.sport?.toLowerCase()?.includes('football') &&
          !data?.sport?.toLowerCase()?.includes('hockey')
        ) {
          return [];
        }
        return [
          ...(previousEvent || []),
          ...(data?.teams?.reduce(
            (previousTeam, currentTeam, currentTeamIndex) => [
              ...previousTeam,
              ...(currentTeam[currentEvent]?.map((el, _i) => {
                return {
                  ...el,
                  type: currentEvent,
                  subType: el?.type,
                  teamName: currentTeam.name,
                  teamImage: currentTeam.image,
                  teamIndex: currentTeamIndex,
                };
              }) || []),
            ],
            []
          ) || []),
        ];
      }, []);
      //  UNSTABLE SORTING ON SOME BROWSERS MIGHT NEED TO WRITE OWN OR IGNORE SUPPORT browsers<2020
      toSort.sort((a, b) => {
        return (
          Number(b.minutes) +
          Math.floor(Number(b.seconds) / 60) -
          Number(a.minutes) +
          Math.floor(Number(a.seconds) / 60)
        );
      });
      return toSort;
    }, [data]) || [];

  const penaltyShootOut = useMemo(() => {
    return [
      data?.teams?.[0]?.goals?.filter((el) => el.type === 'penaltyShootout')?.length,
      data?.teams?.[1]?.goals?.filter((el) => el.type === 'penaltyShootout')?.length,
    ];
  }, [data?.teams?.[0]?.goals, data?.teams?.[1]?.goals]);
  console.log(timeline);
  return (
    <>
      <div
        className={
          'hidden md:flex bg-gray-100 px-8 md:py-4 border-b-1 border-gray-300 justify-between'
        }
      >
        <h1 className={'font-bold font-roboto text-md text-black200 md:mt-1'}>
          {data?.title} {data?.sub_title}
        </h1>
        <div className={'bg-gray-100'}>
          <button
            className={
              'font-roboto bg-gray-750 text-white rounded-lg px-3 p-1 font-medium text-smd'
            }
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </div>

      <div className="font-roboto flex-col items-center max-w-lg mx-auto p-4 bg-white overflow-hidden">
        <button
          data-cy="back_btn"
          className="flex items-center cursor-pointer my-2 md:hidden"
          onClick={closeModal}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            className={`md:fa-xl w-3 md:text-gray-750
           text-gray-750
                  `}
          />
          <p
            data-cy="head"
            className={`md:text-xl text-small md:text-gray-750 
                    text-gray-750
                   md:ml-4 ml-2.5 font-bold`}
          >
            {'Matches and Fixtures'}
          </p>
        </button>
        <div className="md:hidden text-sm font-bold mb-4 text-center text-black200">
          {' '}
          {data?.sport} {data?.sub_title}
        </div>
        <MatchCenterScoreCard
          teamOne={data?.teams?.[0]}
          teamTwo={data?.teams?.[1]}
          scoreOne={data?.teams?.[0]?.goals?.length}
          scoreTwo={data?.teams?.[1]?.goals?.length}
          penaltyShootout={penaltyShootOut || [0, 0]}
          isDraw={data?.is_draw}
        />
        {data?.sport?.toLowerCase()?.includes && <div className="h-0.5 bg-gray-250 mt-2"></div>}
        {(data?.sport?.toLowerCase()?.includes('football') ||
          data?.sport?.toLowerCase()?.includes('hockey')) && (
          <>
            <EventList
              listOne={data?.teams?.[0]?.goals}
              listTwo={data?.teams?.[1]?.goals}
              masterListOne={data?.teams?.[0]?.athletes}
              masterListTwo={data?.teams?.[1]?.athletes}
              icon={<img src={footballIcon} alt={''} />}
            ></EventList>
            <EventList
              listOne={data?.teams?.[0]?.red_cards}
              listTwo={data?.teams?.[1]?.red_cards}
              masterListOne={data?.teams?.[0]?.athletes}
              masterListTwo={data?.teams?.[1]?.athletes}
              icon={<img src={redCard} alt={''} />}
            ></EventList>
          </>
        )}

        {timeline.length > 0 && (
          <div className="mt-8 mb-2">
            <Tabs tabList={[{ name: 'Timeline', component: <></> }]} color="text-black"></Tabs>
          </div>
        )}

        {timeline.map((el, _i) =>
          Cards?.[el.type]?.(
            `${el?.minutes + "'" || ''}`,
            {
              name: data?.teams?.[el.teamIndex]?.athletes?.[
                typeof el.athleteIndex === 'number' ? el.athleteIndex : el?.out
              ]?.name,

              team_image: el.teamImage,
              team: el.teamName,
              image:
                data?.teams?.[el.teamIndex]?.athletes?.[
                  typeof el.athleteIndex === 'number' ? el.athleteIndex : el?.out
                ]?.image,
            },
            {
              name: data?.teams?.[el.teamIndex]?.athletes?.[
                typeof el.athleteIndex === 'number' ? el.athleteIndex : el?.in
              ]?.name,
              team_image: el.teamImage,
              team: el.teamName,
              image:
                data?.teams?.[el.teamIndex]?.athletes?.[
                  typeof el.athleteIndex === 'number' ? el.athleteIndex : el?.in
                ]?.image,
            },
            el
          )
        )}
      </div>
    </>
  );
};

export default MatchCenterPage;
