import React from 'react';
import Helmet from 'react-helmet';
import kheloSchedule from '../../../assets/img/KIYG HARYANA 2022 SCHEDULE 31 May-01.jpg';
import kheloScheduleMobile from '../../../assets/img/KIYG HARYANA 2022 SCHEDULE MOBILE 31 MAY-01.jpg';

export default function index() {
  return (
    <div className="w-full py-10 md:px-0 px-4 mb-2">
      <Helmet>
        <title>Schedule | KIYG Haryana 2021</title>
        <meta
          name="description"
          content="Check the SCHEDULE now! 25 Sports | LIVE from 4th - 13th June 2022. Watch India's finest U/18 athletes represent their respective states at #KIYG2021!"
        />
        <meta property="og:title" content="Schedule | KIYG Haryana 2021" />
        <meta
          property="og:description"
          content="Check the SCHEDULE now! 25 Sports | LIVE from 4th - 13th June 2022. Watch India's finest U/18 athletes represent their respective states at #KIYG2021!"
        />
      </Helmet>
      <div className="py-2">
        <a
          target="_blank"
          rel="noreferrer"
          href={'/static/KIYG HARYANA 2022 SCHEDULE.pdf'}
          download
        >
          Click here to download Full Schedule
        </a>
        {/* <a className="md:hidden" href={kheloSchedule} download>
          Click here to download this schedule
        </a> */}
      </div>
      <img className="m-auto hidden md:block" src={kheloSchedule} />
      <img className="m-auto md:hidden" src={kheloScheduleMobile} />
    </div>
  );
}
