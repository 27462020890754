import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Overview from './Overview';
import Sports from './Sports';
import Results from './Results';
import Schedules from './Schedules';
import DrawsAndSchedules from './DrawsAndSchedule';
import Attractions from './Attractions';
import Gallery from './Gallery';
import Reconciliation from './Reconciliation';
import Embed from './Embed';
import JotForm from './JotForm/JotFormEmbed';
// import desktopBanner from '../../assets/img/Banner 2 June-dekstop.png';
// import mobileBanner from '../../assets/img/Banner 2 June-Mobile.png';

export default function index() {
  const match = useRouteMatch('/micro-site/:tournament_id');
  return (
    <div className="bg-white">
      <Switch>
        <Route path={`${match.url}/landing/schedules`}>
          <Schedules />
        </Route>
        <Route path={`${match.url}/landing/draws-schedules`}>
          <DrawsAndSchedules enabledScheduled={true} />
        </Route>
        <Route path={`${match.url}/landing/media`}>
          <Overview customPropertyFromDetails={'microsite_new_page_builder_id'}></Overview>
        </Route>
        <Route path={`${match.url}/landing/media/:mediaId`}>
          <Overview customPropertyFromDetails={'microsite_new_page_builder_id'}></Overview>
        </Route>
        <Route path={`${match.url}/landing/overview`} component={Overview} />
        <Route exact path={`${match?.url}/landing/support`}>
          <div>
            <JotForm src="https://form.jotform.com/221496086051051" />
          </div>
        </Route>
        <Route exact path={`${match?.url}/landing/feedback`}>
          <div className="">
            <JotForm
              src="https://form.jotform.com/221516022078447"
              className="h-80-screen md:min-h-screen w-full "
              styles={{}}
            />
          </div>
        </Route>
        <Route exact path={`${match?.url}/landing/no-show`}>
          <div>
            <JotForm
              src="https://form.jotform.com/221515744400446"
              className="h-80-screen md:min-h-screen w-full "
              styles={{}}
            />
          </div>
        </Route>
        <div className="md:py-8">
          {/* <img src={desktopBanner} className="md:inline-block hidden mb-2" />
          <img src={mobileBanner} className="inline-block md:hidden mb-2" /> */}
          <Route path={`${match.url}/landing/sports`} component={Sports} />
          <Route path={`${match.url}/landing/results`} component={Results} />
          <Route path={`${match.url}/landing/attractions`} component={Attractions} />
          <Route path={`${match.url}/landing/gallery`} component={Gallery} />

          <Route path={`${match.url}/landing/faq`}>
            <Embed customRoute={'03bbb03c2cfa41bbb23a3f515a28040b'} />
          </Route>
          <Route path={`${match.url}/landing/reconciliation`} component={Reconciliation} />
        </div>
      </Switch>
    </div>
  );
}
