import React from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '../../assets/img/download_icon.svg';
import iconMain from '../../assets/img/microsite/sports/IconMain.svg';
import iconEvent from '../../assets/img/microsite/sports/IconEvent.svg';
import iconVenue from '../../assets/img/microsite/sports/IconVenue.svg';
import { useShowKhelo } from 'utils/HooksUtils';

const CardDetail = ({ index, subContent }) => {
  const isKhelo = useShowKhelo();
  return (
    <div
      className={`md:w-1/3 mt-6 lg:mt-auto text-gray-750 ${
        index === 2 && !subContent.value.address ? 'hidden' : ''
      }`}
    >
      <div className="flex md:flex-col flex-row gap-x-2.5   items-center lg:flex-row lg:items-top">
        <img
          src={index === 0 ? iconMain : index === 1 ? iconEvent : index === 2 ? iconVenue : null}
          className={'md:w-16 md:h-16 w-9 h-9'}
          alt={''}
        />
        <div className={'md:ml-4 '}>
          <h3
            className={`text-xs md:text-sm ${
              isKhelo ? 'text-blue-kheloBlue' : 'text-purple-500'
            } font-semibold`}
          >
            {subContent.header}
          </h3>
          <p
            className={` ${
              index === 0 && typeof subContent.value === 'string' ? 'whitespace-nowrap' : ''
            } md:w-4/5 ${
              index === 0 ? 'md:font-bold md:text-xl text-sm' : 'text-sm mt-1 md:mt-auto'
            }`}
          >
            {index === 0 && typeof subContent.value === 'string'
              ? subContent.value
              : typeof subContent.value === 'object'
              ? index === 1
                ? `From ${new Intl.DateTimeFormat('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  }).format(new Date(subContent.value.from))} 
                 To ${new Intl.DateTimeFormat('en-US', {
                   year: 'numeric',
                   month: 'long',
                   day: 'numeric',
                 }).format(new Date(subContent.value.to))}`
                : index === 2
                ? subContent.value.title
                : null
              : null}
          </p>
          {index === 2 ? <p className={`w-4/5 text-sm`}>{subContent.value.address}</p> : null}
        </div>
      </div>
      <div className={`${subContent.pdf_url ? 'flex' : 'hidden '} gap-4 mt-4`}>
        <img src={DownloadIcon} alt="download icon" />
        <a href="#" className={`text-sm ${isKhelo ? 'text-blue-kheloBlue' : 'text-purple-550'}`}>
          Download Rules and Guidelines PDF
        </a>
      </div>
    </div>
  );
};

CardDetail.propTypes = {
  index: PropTypes.number,
  subContent: PropTypes.object,
};

export default CardDetail;
