// Micro-site Sports Page -- /landing/sports/<name>
export const filtersText = 'No sport events for selected filters';
export const drawsText = 'This information will be updated soon.';
export const rankingRecentText = 'This information will be updated after the Games begin.';
export const galleryText = 'Keep a close watch on this space to find out the pictures of ';
export const rulesText = 'This information will be updated soon.';

// Micro-site Draws & Schedule Page -- /landing/draws-schedules
export const tableText = 'This information will be updated soon.';

// Micro-site Results Page -- /landing/results
export const rankingTableText = 'This information will be updated after the Games begin.';
export const recentWinnersText = 'This information will be updated after the Games begin.';
export const fullRankingTableKheloText =
  'Keep A Close Watch On This Space To Find Out The Best State Level Performance in KIYG.';
export const fullRankingTableSFAText =
  'Keep A Close Watch On This Space To Find Out The Best State Level Performance in KIYG.';

// Micro-site Gallery Page -- /landing/gallery
export const noMediaText = 'Stay tuned , Images and Videos will be updated shortly';

// Micro-site News Page -- /landing/news
export const noNewsText = 'No Media to show right now.';

// Digital-Scoring Page
export const noDataText = 'This information will be updated soon';
export const noRoundsHeatsText = 'Rounds are not created yet for this sport event';
export const noMedalWinnersHighJumpLongJumpText = 'Medal Winners Are Yet To Be Declared.';
export const noRoundsSwissText = 'Rounds are not created yet for this sport event';

// Profiles Match & Fixtures Page -- Athlete Fixtures
export const noAthleteFixturesDataText = 'This information will be updated soon.';

// Profiles Match & Fixtures Page -- Coach Fixtures
export const noTeamMatchText = 'Your team does not have any matches today.';

// Profiles Events Page
export const noEventsAthleteText = 'Your events for the Championship will be updated here';
export const noEventsCoachText = 'Your events for the Championship will be updated here';
export const noEventsInstitutionText = 'Your events for the Championship will be updated here';
export const coachVerificationPendingText =
  'Kindly Note Your Verification Status Confirmed Within 3 Days. Upon Successful Verification You Shall Be Able To Generate Team Registration Links.';
export const coachNoAthleteFoundText = 'No Athletes (Students) Found.';
export const coachNoMatchFixturesText =
  'Match Fixtures & Results for your team will be displayed here.';
export const coachTeamAddLoadingText =
  'Kindly Note Your Teams For The Championship Will Be Displayed Here';
