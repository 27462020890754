import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import SelectList from 'components/select-list';
import Loader from 'components/Loader';
import 'components/formio/formio.css';
import 'constants/DrawEventForm/style.css';
import Table from '../../../Components/Table';

export default function WinnerList({
  sport,
  setSport,
  categoryList,
  winnerTableFullQueryData,
  setScreen,
  winnerColumns,
}) {
  return (
    <>
      <div className="md:mt-8 px-4 md:px-16">
        <div
          className="flex gap-2 cursor-pointer items-center mb-8"
          onClick={() => setScreen('main_page')}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          <p className="font-bold">Result</p>
        </div>
        <div className="flex justify-between shadow-shadowBottom pb-2 items-center">
          <p className="text-2xl font-bold">Winners List</p>
        </div>
        <div className="flex justify-between mt-8">
          <div className="flex items-center gap-2.5">
            <div className="w-40">
              <SelectList
                selectedValue={sport}
                setSelectedValue={setSport}
                placeholder="Sport"
                listData={categoryList}
              />
            </div>
            {/* <div className="w-40">
                  <SelectList
                    selectedValue={event}
                    setSelectedValue={setEvent}
                    placeholder="Event"
                    listData={categoryList}
                  />
                </div>
                <div className="w-40">
                  <SelectList
                    selectedValue={gender}
                    setSelectedValue={setGender}
                    placeholder="Gender"
                    listData={categoryList}
                  />
                </div>
                <div className="w-40">
                  <SelectList
                    selectedValue={ageCategory}
                    setSelectedValue={setAgeCategory}
                    placeholder="Age Category"
                    listData={categoryList}
                  />
                </div> */}
            {/* <div className="w-40">
                            <SelectList
                                selectedValue={medal}
                                setSelectedValue={setMedal}
                                placeholder="Medal"
                                listData={categoryList}
                            />
                        </div> */}
            {/* <div className="w-40">
                  <SelectList
                    selectedValue={round}
                    setSelectedValue={setRound}
                    placeholder="Round"
                    listData={categoryList}
                  />
                </div> */}
          </div>
        </div>
        <div></div>
        <div className="mt-4">
          {winnerTableFullQueryData.data ? (
            winnerTableFullQueryData?.data?.pages?.[0]?.data?.length > 0 ? (
              <Table
                tableHeight={'600px'}
                columns={winnerColumns}
                rows={winnerTableFullQueryData?.data}
                isInfinite
                isInfiniteFetching={winnerTableFullQueryData.isFetching}
                fetchNext={winnerTableFullQueryData.fetchNextPage}
              />
            ) : (
              <div className="bg-purple-950 text-white text-center rounded-lg py-8 mt-8 mb-44">
                No Winners {sport ? `for ${sport}` : ''}
              </div>
            )
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
}
